import React from 'react';
import './App.css';
import fhb_logo from './fhb_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

function App() {

  const donateNow = () => {
    window.open("https://fly-high-bella.myshopify.com/products/dona-1", "_blank");
  }

  return (
    <React.Fragment>
      <div className='menu-container'>
        <a href='https://fly-high-bella.myshopify.com/' target="_blank"><div className='menu-item'>Store</div></a>
        <a href='#about'><div className='menu-item'>Who we are</div></a>
        <a href="#mission"><div className='menu-item'>Mission</div></a>
        <a href="#vision"><div className='menu-item'>Vision</div></a>
        <a href="#footer"><div className='menu-item'>Contact</div></a>
      </div>

      <div className='hero-container'>

        <div className='hero-content-container'>
            <div className='hero-content-item hero-donate'>
              <h1 className='hero-title'>Bella's Fly High</h1>
              <h2 className='hero-title-2'>Foundation</h2>
              <h2 className='hero-sub-title'></h2>
              <input type="button" onClick={donateNow} className='button' value="Donate Now" />
            </div>
            <div className='hero-content-item hero-img'>
              <img src={fhb_logo} className='logo' />
            </div>
        </div>

      </div>
      
      <div className='about-container' id="about">
        <div className='about-content-container'>
          <div>
            <h1 className='about-title'>Who we are</h1>
          </div>
        </div>
        <div className='about-content-container'>
          <div>
            <p className='about-text'>
            As committed parents and community members, creating and promoting a charitable cause would have only crossed our minds if it would have been done together with our daughter Bella.
            </p>
            <p className='about-text'>
            Upon a sudden and unexpected accident, where in the midst of a winter snow storm, a 50-foot, 2-ton tree fell on top of the rooftop of a vacation home, where sweet Bella Benvenutti was sleeping in the morning of January 3rd, 2022, during a family winter vacation in the Tennessee Smokey Mountains.
            </p>
            <p className='about-text'>
            Bella Benvenutti was a loving, caring and bright 7-year old Second Grader from San Juan, Puerto Rico who left a magical footprint of joy in each life she touched with her contagious laughter selfless kindness, generosity and inner beauty.
            </p>
            <p className='about-text'>
            Amid deep grief, Bella’s parents, family and community have rallied in heart, spirit and action in the humblest and most sincere forms of coping with this tragic loss to all of us who loved and admired this precious child.
            </p>
            <p className='about-text'>
            Inspired in the principle of giving and the foundations of community life, Bella’s Fly High Foundation, Corp. came to life as a form of continuing the connection so abruptly torn by the events of January 3rd, 2022.
            </p>
          </div>
        </div>
        <svg id="" preserveAspectRatio="xMidYMax meet" className="" viewBox="0 0 1600 200" style={{display: "block"}} data-height="200">
          <polygon className="" style={{fill: "#C3B1E1"}} points="-4,0 1604,198 1604,204 -4,204 	"></polygon>
          <polygon className="" style={{opacity: 1, fill: "#d2d7d3"}} points="1604,198 1604,186 -4,0 -4,0 	"></polygon>
          <polygon className="" style={{opacity: 1, fill: "#bdc3c7"}} points="1604,186 1604,174 -4,0 -4,0 	"></polygon>
        </svg>
      </div>
      <div className='mission-container' id="mission">
        <div className='mission-item'>
          <h1 className="mission-title">Mission</h1>
          <p className='mission-text'>
            The gift of giving is the driving force of Bella’s Fly High Foundation. Three pillars of giving children of Puerto Rico access to education:
          </p>
          <div className='mission-text'>
            <ul>
              <li>
                <strong style={{fontSize: 32}}>Fly High Clinics</strong>
                <ul>
                  <li><strong>Getting off the training wheels</strong> – teach children of all ages the safety, skills and attitude to learn how to ride a bike, dropping their training wheels</li>
                  <li><strong>My first tennis lesson</strong> – Gateway tennis lessons course</li>
                  <li><strong>Ride my first wave</strong> – Gateway surf lessons course</li>
                </ul>
              </li>
              <li>
                <strong style={{fontSize: 32}}>FlyHighBella scholarship program</strong>
                <ul>
                  <li>Financial assistance program for a qualified student, teamed with Bella’s alma mater Saint John’s School in San Juan, Puerto Rico.
                  Opening the doors of quality private education to outstanding children and their families whose financial circumstances would have denied such opportunity.
                  </li>
                </ul>
              </li>
              <li>
                <strong style={{fontSize: 32}}>Access to quality education</strong>
                <ul>
                  <li>Redevelopment of a closed-down school campus in Puerto Rico</li>
                  <li>Bilingual, college preparatory academic program</li>
                </ul>
              </li>
            </ul>
          </div>
          <p className='mission-text'>
          Through donations, the sale of <i>flyhighbella</i> memorabilia articles and the production of fundraising events, our nonprofit will contribute in the development of fine children in our Puerto Rican community and beyond.
          </p>
        </div>
        <div className='mission-item' id="vision">
          <h1 className="mission-title">Vision</h1>
          <p className='mission-text'>
          Inspired in the charismatically and contagious views of caring sweet Bella exhibited with friends, family, and the community, through the gift of giving, Bella’s Fly High Foundation is a space to fill the hearts of all those who miss her presence through the gift of giving education and recreation.
          </p>
        </div>
      </div>
      <div className='featured-container' id="featured">
        <div className='featured-title-container'>
          <div>
            <h1 className='featured-title'>Support the Cause</h1>
          </div>
          <div>
            <h2>100% of all purchases are destined towards helping a student overachieve.</h2>
          </div>
        </div>
        {/* <div className='featured-item-container'>
          <div className='featured-item'>
            <p>item 1</p>
          </div>
          <div className='featured-item'>
            <p>item 2</p>
          </div>
          <div className='featured-item'>
            <p>item 3</p>
          </div>
        </div> */}
      </div>
      <div className='footer-container' id="footer">
        <div className='footer-divider'>
          <svg id="" preserveAspectRatio="xMidYMax meet" className="" viewBox="0 0 1600 200" data-height="200">
            <polygon className="" style={{fill: "#C3B1E1"}} points="886,86 800,172 714,86 -4,86 -4,204 1604,204 1604,86 "></polygon> 
            <polygon className="" style={{opacity: 1, fill: "#C3B1E1"}} points="800,172 886,86 900,86 800,186 700,86 714,86 "></polygon> 
            <polygon className="" style={{opacity: 1, fill: "#C3B1E1"}} points="800,162 876,86 888,86 800,174 712,86 724,86 "></polygon>
          </svg>
        </div>
        <div className='footer-content-container'>
          <div className='footer-content-item'>
            <h1 className='footer-title'>Contact</h1>
          </div>
        </div>
        <div className='footer-content-container'>
          <div className='footer-content-item'>
            <p className='footer-email'>Email: <a href='mailto:info@flyhighbella.org'>info@flyhighbella.org</a></p>
          </div>
        </div>
        <div className='footer-content-container'>
          <div className='footer-content-item'>
            <FontAwesomeIcon className='social' icon={faFacebookF} />
          </div>
          <div className='footer-content-item'>
            <FontAwesomeIcon className='social' icon={faInstagram} />
          </div>
          <div className='footer-content-item'>
            <FontAwesomeIcon className='social' icon={faTwitter} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;
